import axios from 'axios';
import http from './http';

export const login = (data) => {
    return http.post(`/auth/administrant/login`, data);
}

export const logout = (data) => {
    return http.post(`/auth/administrant/logout`, data);
}

export const forgotPassword = (data) => {
    return http.post(`/auth/administrant/forgot`, data);
}

export const deleteOrganizationUser = (query) => {
    return http.delete(`/auth/administrant/delete?org_email=${query}`
    );
}
export const resetCredential = (data) => {
    return http.post(`api/v1/org/administrant/org/signup`, data);
}